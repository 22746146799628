import Repository from "@/repository/Repository";
const resources = "/api/v1/questions";

export default {
    getAll() {
        return Repository.get(`${resources}`)
    },

    getQuestionsByPage(page) {
        return Repository.get(`${resources}/page`, {
            params: page
        });
    },

    getQuestionInGroupByPage(id, page) {
        return Repository.get(`${resources}/groups/${id}/page`, {
            params: page
        });
    },

    createQuestion(question) {
        return Repository.post(`${resources}`, question);
    },

    getSingleQuestion(id) {
        return Repository.get(`${resources}/${id}`)
    },

    searchByContent(content) {
        return Repository.get(`${resources}/search`, {
            params: {
                content
            }
        })
    },

    searchInGroupByContent(id, content) {
        return Repository.get(`${resources}/groups/${id}/search`, {
            params: {
                content
            }
        });
    },

    updateQuestion(id, question) {
        return Repository.put(`${resources}/${id}`, question)
    },

    publishQuestions(questions) {
        return Repository.put(`${resources}/publish`, questions)
    },

    unPublishQuestions(questions) {
        return Repository.put(`${resources}/unpublish`, questions)
    }
}