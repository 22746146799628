<template>
    <a-layout class="App">
        <router-view v-if="isAuthenticated"></router-view>
    </a-layout>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import http from 'http';
    import https from 'http';
    import axios from "axios";
    import axiosRepository from "@/repository/Repository"

    axios.defaults.httpAgent = new http.Agent({ keepAlive: true });
    axios.defaults.httpsAgent = new https.Agent({ keepAlive: true });

    export default {
        name: "app",
        data() {
            return {
                isAuthenticated: false
            };
        },
        methods: {
            ...mapActions('authenticate',[
                'setUser', 'setCredentials', 'setUserPermissions'
            ]),
            ...mapActions('staticData', [
                'setSalons', 'setSalonAreas'
            ])
        },
        computed: {
            ...mapGetters('staticData', [
                'getSalons'
            ])
        },

        async created() {
            try {
                // eslint-disable-next-line no-undef
                const creds = await ssoLogin();
                axios.defaults.headers.common["Authorization"] = "Bearer " + creds.AccessToken;
                axiosRepository.defaults.headers.common["Authorization"] = "Bearer " + creds.AccessToken;

                this.setCredentials({credentials: creds});
                await this.setUser();
                await this.setUserPermissions();
                this.isAuthenticated = true;
                this.setSalons();
                this.setSalonAreas();
            } catch (e) {
                // eslint-disable-next-line no-console
                console.log(e.message);
            }
        },

        async destroyed() {
            localStorage.removeItem("userPermissions");
        }
    };
</script>

<style lang="scss">
    .App {
        background-color: #31343d;
    }
</style>
