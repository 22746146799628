import Vue from 'vue'
import Router from 'vue-router'
import { match } from 'path-to-regexp'
import App from '../App';
const TrainingStaffAdmin = () => import('../pages/TrainingStaffAdmin')
const Dashboard = () => import('../pages/Dashboard/Dashboard')
const QuestionList = () => import('../pages/Question/QuestionList')
const QuestionGroup = () => import('../pages/QuestionGroup/QuestionGroup')
const QuestionGroupReport = () => import('../pages/Report/QuestionGroupReport')
const TestCompleteReport = () => import('../pages/Report/TestCompleteReport')
const TestHistoryReport = () => import('../pages/Report/TestHistoryReport')
const QuestionContentReport = () => import('../pages/Report/QuestionContentReport')
const TrainingIgnoreSetting = () => import('../pages/Setting/TrainingIgnoreSetting')
const page404 = () => import("../pages/Error/page404");
const page403 = () => import("../pages/Error/403");

Vue.use(Router)

let router = new Router({
    routes: [
        {
            path: '/',
            component: App,
            children: [
                {
                    path: '',
                    component: TrainingStaffAdmin,
                    children: [
                        {
                            path: '',
                            name: 'Dashboard',
                            component: Dashboard,
                        },
                        {
                            path: 'dashboard',
                            name: 'Dashboard',
                            component: Dashboard,
                        },
                        {
                            path: 'ds-cau-hoi',
                            name: 'Danh sách câu hỏi',
                            component: QuestionList,
                            meta: {
                                requireAuth: true
                            }
                        },
                        {
                            path: 'ds-cau-hoi/nhom/:groupId',
                            name: 'Danh sách câu hỏi theo nhóm',
                            component: QuestionList,
                            meta: {
                                requireAuth: true
                            }
                        },
                        {
                            path: 'nhom-cau-hoi',
                            name: 'Nhóm câu hỏi',
                            component: QuestionGroup,
                            meta: {
                                requireAuth: true
                            }
                        },
                        {
                            path: 'bao-cao-ti-le-test',
                            name: 'Báo cáo tỉ lệ hoàn thành bài test',
                            component: TestCompleteReport,
                            meta: {
                                requireAuth: true
                            }
                        },
                        {
                            path: 'bao-cao-ti-le-nhom-cau-hoi',
                            name: 'Báo cáo tỉ lệ trả lời đúng theo nội dung test',
                            component: QuestionGroupReport,
                            meta: {
                                requireAuth: true
                            }
                        },
                        {
                            path: 'bao-cao-lich-su-test',
                            name: 'Báo cáo lịch sử làm bài test',
                            component: TestHistoryReport,
                            meta: {
                                requireAuth: true
                            }
                        },
                        {
                            path: 'bao-cao-tong-quan-theo-cau-hoi',
                            name: 'Báo cáo tổng quan theo câu hỏi',
                            component: QuestionContentReport,
                            meta: {
                                requireAuth: true
                            }
                        },
                        {
                            path: 'ignore-salon-setting',
                            name: 'Cài đặt không bắt buộc làm bài test theo salon',
                            component: TrainingIgnoreSetting,
                            meta: {
                                requireAuth: true
                            }
                        },
                        {
                            path: '/403',
                            component: page403
                        },
                        {
                            path: "*",
                            name: 'page404',
                            component: page404
                        }
                    ]
                }
            ]
        }
    ],
    mode: 'history',
    linkActiveClass: "active", // active class for non-exact links.
    linkExactActiveClass: "exact-active" // active class for *exact* links.
});

router.beforeEach((to, from, next) => {
    let userPermissionString = localStorage.getItem("userPermissions");
    if (userPermissionString === null || userPermissionString.length === 0) {
        next({ path: '/403'})
    }
    const userPermissions = JSON.parse(userPermissionString);
    if (to.matched.some(record => record.meta.requireAuth) &&
        !userPermissions.some( permission => {
            let checkPath = match(permission.pageId, { decode: decodeURIComponent });
            return checkPath(to.fullPath)
        })) {
        next("/403");
    } else {
        next();
    }
});

export default router;