import Repository from "@/repository/Repository";
const resources = "/api/v1/ignores";

export default {
    getAll() {
        return Repository.get(`${resources}`)
    },

    updateSetting(id, setting) {
        return Repository.put(`${resources}/${id}`, setting)
    },

    deleteSetting(id) {
        return Repository.delete(`${resources}/${id}`)
    }
}