import questionGroupRepository from "@/repository/questionGroupRepository";
import questionRepository from "@/repository/questionRepository";
import reportRepository from "@/repository/reportRepository";
import salonAreaRepository from "@/repository/salonAreaRepository";
import salonRepository from "@/repository/salonRepository";
import trainingIgnoreSettingRepository from "@/repository/trainingIgnoreSettingRepository";

const repositories = {
    questionGroup: questionGroupRepository,
    question: questionRepository,
    report: reportRepository,
    salonArea: salonAreaRepository,
    salon: salonRepository,
    ignoreSetting: trainingIgnoreSettingRepository
}

export const RepositoryFactory = {
    get: name => repositories[name]
}