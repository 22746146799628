import { AUTHENTICATE_SET_CREDENTIALS, AUTHENTICATE_SET_USER, AUTHENTICATE_SET_PERMISSIONS } from '../../mutation-types';

export default {
    [AUTHENTICATE_SET_CREDENTIALS](state, { credentials }) {
        state.credentials = credentials;
    },
    [AUTHENTICATE_SET_USER](state, { user }) {
        state.user = user
    },
    [AUTHENTICATE_SET_PERMISSIONS](state, { userPermissions }) {
        state.userPermissions = userPermissions;
    }
};