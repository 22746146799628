export function getSalons(state) {
    return state.salons;
}

export function getSalonAreas(state) {
    return state.salonAreas;
}

export function getCategories(state) {
    return state.categories;
}
