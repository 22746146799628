import {AUTHENTICATE_SET_USER, AUTHENTICATE_SET_CREDENTIALS, AUTHENTICATE_SET_PERMISSIONS} from '../../mutation-types';
import axios from "axios";
import axiosRepository from '@/repository/Repository';
import config from '../../../configs/ApplicationConfig';

export default {
    async setUser({ commit }) {
        const { data: user } = await axios.get(config.authorEndpoint + "/api/permissions/salons");
        commit(AUTHENTICATE_SET_USER, { user });
    },
    async setCredentials({ commit }, credentials) {
        commit(AUTHENTICATE_SET_CREDENTIALS, { credentials })
    },

    async refreshToken({commit, state}) {
        const credentials = state.credentials;
        const { data } = await axios.put("https://api-staff.30shine.com/api/login/refresh-token", null, {
            headers: {
                accessToken: credentials.AccessToken,
                idToken: credentials.IdToken,
                refreshToken: credentials.RefreshToken
            }
        });

        // eslint-disable-next-line no-console
        console.log(data.accessToken);

        axiosRepository.defaults.headers.common["Authorization"] = "Bearer " + data.accessToken;
        credentials.AccessToken = data.accessToken;
        commit(AUTHENTICATE_SET_CREDENTIALS, { credentials });
    },

    async setUserPermissions({commit}) {
        try {
            const data = await axios.get( config.authorEndpoint + "/api/permissions/menus");
            const up = data.data.find( item  => item.meta.title === 'Dashboard eLearning');
            const userPermissions = up ? up.children : [];
            localStorage.setItem("userPermissions", JSON.stringify(userPermissions))
            commit(AUTHENTICATE_SET_PERMISSIONS, { userPermissions: userPermissions })
        } catch (e) {
            localStorage.setItem("userPermissions", JSON.stringify([]))
            commit(AUTHENTICATE_SET_PERMISSIONS, { userPermissions: [] })
        }
    },
}