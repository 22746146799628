import {DATA_SET_SALONS, DATA_SET_SALONAREAS, DATA_SET_CATEGORIES} from '../../mutation-types';
import {RepositoryFactory} from "@/repository/RepositoryFactory";
const questionGroupRepository = RepositoryFactory.get("questionGroup");
import axios from 'axios';
import config from '../../../configs/ApplicationConfig';

export async function setSalons({ commit }) {
    let salons = [];
    let response = await axios.get(config.baseEndpoint + '/api/v1/salons');
    if (response.data.meta.success) {
        // Reject report SALONTEST id 24
        salons = response.data.data.filter( salon => salon.id !== 24);
    }

    commit(DATA_SET_SALONS, { salons });
}

export async function setSalonAreas({ commit }) {
    let salonAreas = [];
    let response = await axios.get(config.baseEndpoint + '/api/v1/salon-areas');
    if (response.data.meta.success) {
        salonAreas = response.data.data;
    }

    commit(DATA_SET_SALONAREAS, { salonAreas });
}

export async function setRemoteCategories({ commit }) {
    let categories = [];
    try {
        let response = await questionGroupRepository.getAll();
        if (response.data.meta.success) {
            categories = response.data.data;
        } else {
            throw new Error();
        }
    } catch (e) {
        categories = [];
    }
    commit(DATA_SET_CATEGORIES, { categories });
}

export async function setLocalCategories({ commit }, categories) {
    commit(DATA_SET_CATEGORIES, { categories })
}