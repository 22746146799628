import Repository from "@/repository/Repository";
const resources = "/api/v1/question-groups";

export default {
    getAll() {
        return Repository.get(`${resources}`)
    },

    getGroupByPage(page) {
        return Repository.get(`${resources}/page`, {
            params: page
        });
    },

    createGroup(group) {
        return Repository.post(`${resources}`, group);
    },

    getGroup(id) {
        return Repository.get(`${resources}/${id}`)
    },

    updateGroup(id, group) {
        return Repository.put(`${resources}/${id}`, group)
    },

    deleteGroup(id) {
        return Repository.delete(`${resources}/${id}`)
    }

}