import Repository from "@/repository/Repository";
const resources = "/api/v1/reports";

export default {
    testCompleteReport({ workDateStart, workDateEnd, salonIds }) {
        return Repository.post(`${resources}/test-complete`, {
            workDateStart,
            workDateEnd,
            salonIds
        })
    },
    questionGroupReport({ workDateStart, workDateEnd, salonIds }) {
        return Repository.post(`${resources}/question-group`, {
            workDateStart,
            workDateEnd,
            salonIds
        })
    },

    questionGroupReportStatic({ workDateStart, workDateEnd, salonIds }) {
        return Repository.post(`${resources}/question-group/static`, {
            workDateStart,
            workDateEnd,
            salonIds
        })
    },

    questionContentReport({ staffId, workDateStart, workDateEnd }) {
        return Repository.get(`${resources}/question-contents`, {
            params: {
                staffId, workDateStart, workDateEnd
            }
        });
    },

    questionContentDetail({ staffId, questionId, workDateStart, workDateEnd }) {
        return Repository.get(`${resources}/question-contents/details`, {
            params: {
                staffId, questionId, workDateStart, workDateEnd
            }
        });
    },

    testHistoryReport({ salonId, workDateInt }) {
        return Repository.get(`${resources}/test-history`, {
            params: {
                salonId, workDateInt
            }
        })
    },

    testHistoryDetails({ staffTurnId }) {
        return Repository.get( `${resources}/test-history/details`,{
            params: {
                staffTurnId
            }
        });
    }
}